export const styleConstants = {
    HEADER_HEIGHT: 60,
    HEADER_X_PADDING: 0,
    PADDING_X_HOME: 20,
    PADDING_Y_HOME: 10,
    PRIMARY_COLOR: "#FFFFFF",
    SECONDARY_COLOR: "#000000",
    BACKGROUND_COLOR_LAYOUT: "#000",
    FILTER_SIDEBAR_OPEN: '80px',
    FILTER_SIDEBAR_DEFAULT: '40px',
}

export const getPrimaryColorByAccountId = (accountId: string): string => {
  switch (accountId) {
    case "agudojhonny":
      return "#f80303"
    case "rifas-trebol":
      return "#f6ce55"
    default: 
      return "#0a0a5c"
  }
}

export const getThemeByAccountId = (accountId: string): "dark" | "light" => {
  switch (accountId) {
    case "oscar-alarcon":
    case "galenstar":
    case "oscar-alarcon-promo":
    case "danielcontrerasrifas":
    case "vip-rifas":
    case "maikel-dominguez":
    case "blessed-disc-bar-zz":
    case "rifa-sport":
    case "rifas-trebol":
    case "goyo2time":
      return "dark"
    case "maikel-jaimez":
    case "jjmc":
    case "agudojhonny":
      return "light"
    default: 
      return "dark"
  }
}

export const buttonAddStyle = {
    mt: 2,
    marginLeft: 1,
    padding: 0,
    border: 0,
    textDecoration: "underline",
  };